import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import checkbox from "../images/path.png";
import section1img1 from "../images/frame1.png";
import section1img2 from "../images/frame2.png";
import section1img3 from "../images/frame3.png";
import section1img4 from "../images/frame4.png";
import section2img from "../images/section2img.png";
import uprightarrow from "../images/uprightarrow.png";
// import div from "react-animate-on-scroll";
import section4img from "../images/section4img.png";
import section6img from "../images/section6img.png";
import homeSegment3img1 from "../images/homeSegment3img1s.jpg";
import homeSegment3img2 from "../images/homeSegment3img2.jpg";
import homeSegment3img3 from "../images/homeSegment3img3.jpg";
import homeSegment3img4 from "../images/homeSegment3img4.jpg";
import star from "../images/star.png";
import homesegment8bgIMG from "../images/newhomesegment8bgIMG.jpg";
import HomeSegment2IMG from "../images/homeSegment2IMG.jpg";
import GgreenArrow from "../images/GgreenArrow.png";
import ContactForm from '../components/ContactForm';
import fbq from 'react-facebook-pixel';

function Home() {
  return (
    <div>
      <section className="section1 p-3 p-md-5">
        <div>
          <div className="lhsheading">
            {" "}
            <span class="thick-dot"></span>{" "}
            <span className="ps-1"> HEALTH AND CARE COMPANIONSHIP</span>{" "}
          </div>
          <div className="section1exp pt-2 pt-md-1">
            A Caring Hand
            <span className="section1expblue"> When You </span> Need It Most
          </div>
          <div className="section1paragraph pt-3">
            Coming home after a long day, feeling overwhelmed, and your loved
            one needs more care than you can manage on your own, or perhaps
            you’re seeking extra support to help them recover.
            <br /> Well, that’s why we are here for you. <br /> <br />
            At Brooks Health and Care, we are dedicated to delivering
            compassionate, personalised caregiving services that cater to the
            unique needs of every individual. Making sure you receive the
            highest level of care in the comfort of your home.
          </div>
          <div className="section1list pt-md-5 pt-3">
            <div className="section1listname pb-2">
              {" "}
              <span className="pe-2">
                <img src={checkbox} alt="checkbox" />
              </span>{" "}
              Experienced and Professional Caregiver{" "}
            </div>
            <div className="section1listname pb-2">
              {" "}
              <span className="pe-2">
                <img src={checkbox} alt="checkbox" />
              </span>{" "}
              Tailored Care Plans Just for You{" "}
            </div>
            <div className="section1listname pb-2">
              {" "}
              <span className="pe-2">
                <img src={checkbox} alt="checkbox" />
              </span>{" "}
              Peaceful, Supportive Environment{" "}
            </div>
            <div className="section1listname pb-2">
              {" "}
              <span className="pe-2">
                <img src={checkbox} alt="checkbox" />
              </span>{" "}
              Holistic Approach to Your Well-Being{" "}
            </div>
          </div>
          <div className="pt-5">
          <a href="#contact" className="testimonialbtn">
                <span className="pe-2">Book An Appointment With Us Today!</span>{" "}
                <img
                  src={uprightarrow}
                  alt="arrow"
                  className="homeSegment6testimoialarrow"
                />
                </a>
          </div>
          <div className="section1paragraph pt-4"> 
          Improving your well-being with personalised care that puts you first is our commitment. Our compassionate caregivers and medical staff work collaboratively with you and your loved ones to provide a care experience that is not only professional but also tailored to you.

          </div>
        </div>
        <div>
          <div className="section1galleryi pb-2">
            <div>
              <img className="lpimg w-100" src={homeSegment3img2} alt="" />
            </div>
          </div>
          <div className="section1galleryii">
            <div>
              <img className="lpimg w-100" src={homeSegment3img4} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="section3 p-md-5 p-3" id="services">
        <div className="section3headingcontainer">
          <div>
            <div className="lhsheading lhsheading2">
              {" "}
              <span className=""> OUR SERVICES </span>{" "}
            </div>
            <div className=" section2exp">
              <span className="section1expblue"> Check Out</span> Our Caregiving
              Services
            </div>
          </div>
          <div>
            <div className="section3headingpara">
              We offer a wide range of care services tailored to meet your
              specific needs—all delivered with kindness, professionalism, and a
              personal touch.
            </div>
            <div className="pt-3">
              <a href="#contact" className="testimonialbtn">
                <span className="pe-2">Book Appointment</span>{" "}
                <img
                  src={uprightarrow}
                  alt="arrow"
                  className="homeSegment6testimoialarrow"
                />
              </a>
            </div>{" "}
          </div>
          <div></div>
        </div>

        <div className="aboutsegment3container">
          <div
            className="aboutsegment3box"
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div>
              <i class="aboutsegment3icon fa-solid fa-droplet"></i>
            </div>
            <div className="aboutsegment3boxtitle py-md-3 py-2">
              Home Care Support
            </div>
            <div className="aboutsegment3boxdesc">
              Assistance with daily activities in the comfort of your home,
              ensuring you or your loved one feel safe and supported.
            </div>
          </div>
          <div
            className="aboutsegment3box"
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div>
              <i class="fa-solid aboutsegment3icon fa-heart"></i>
            </div>
            <div className="aboutsegment3boxtitle py-md-3 py-2">
              Personalised Care
            </div>
            <div className="aboutsegment3boxdesc">
              Customized care plans tailored to meet individual health and
              lifestyle needs.
            </div>
          </div>
          <div
            className="aboutsegment3box"
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div>
              <i class="fa-solid fa-people-group aboutsegment3icon"></i>
            </div>
            <div className="aboutsegment3boxtitle py-md-3 py-2">
              Live-in Care
            </div>
            <div className="aboutsegment3boxdesc">
              Round-the-clock support from dedicated caregivers who live with
              you to provide continuous care.
            </div>
          </div>
          <div
            className="aboutsegment3box"
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div>
              <i class="fa-solid fa-address-card aboutsegment3icon"></i>
            </div>
            <div className="aboutsegment3boxtitle py-md-3 py-2">
              Respite Care{" "}
            </div>
            <div className="aboutsegment3boxdesc">
              Temporary relief for primary caregivers, giving them time to
              recharge while ensuring their loved ones are in safe hands.
            </div>
          </div>
          <div
            className="aboutsegment3box"
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div>
              <i class="fa-solid fa-star aboutsegment3icon"></i>
            </div>
            <div className="aboutsegment3boxtitle py-md-3 py-2">
              Full Body Massage{" "}
            </div>
            <div className="aboutsegment3boxdesc">
              Experience complete relaxation and revitalization with our full
              body massage. This comprehensive treatment addresses all major
              muscle groups, enhancing circulation, reducing stress, and
              promoting overall physical and mental well-being.
            </div>
          </div>
          <div
            className="aboutsegment3box"
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div>
              <i class="fa-solid fa-bed aboutsegment3icon"></i>{" "}
            </div>
            <div className="aboutsegment3boxtitle py-md-3 py-2">
              Nights/Sleep-in Care
            </div>
            <div className="aboutsegment3boxdesc">
              Overnight assistance for those needing peace of mind and support
              through the night.
            </div>
          </div>
          <div
            className="aboutsegment3box"
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div>
              <i class="fa-solid fa-bed aboutsegment3icon"></i>{" "}
            </div>
            <div className="aboutsegment3boxtitle py-md-3 py-2">
              1-1 Support
            </div>
            <div className="aboutsegment3boxdesc">
              Individualized, one-on-one care tailored to your specific needs.
            </div>
          </div>
          <div
            className="aboutsegment3box"
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div>
              <i class="fa-solid fa-people-group aboutsegment3icon"></i>{" "}
            </div>
            <div className="aboutsegment3boxtitle py-md-3 py-2">
              Care Support for Travel
            </div>
            <div className="aboutsegment3boxdesc">
              Providing care and support during your travels, ensuring you enjoy
              your trips stress-free.{" "}
            </div>
          </div>
        </div>
      </section>

      <div className="aboutsegment3 p-4 p-md-5 " id="benefits">
        <div className="d-flex pt-5 ">
          <div className="homeSegment1aboutusline"></div>
          <div className="homeSegment1aboutus ps-2 ">WHY CHOOSE US?</div>
        </div>
        <div className="homeSegment3intro py-4">
          Discover why Brooks Health and Care is your <br />
          top choice for home health care.
        </div>
        <div className="aboutsegment3container">
          <div
            className="aboutsegment3box"
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div>
              <i class="aboutsegment3icon fa-solid fa-heart-circle-check"></i>
            </div>
            <div className="aboutsegment3boxtitle py-3">
              Holistic Well-Being
            </div>
            <div className="aboutsegment3boxdesc">
              At Brooks Health and Care, we prioritize compassion in every
              interaction. Our dedicated team of caregivers and healthcare
              professionals is committed to providing empathetic and supportive
              care to our service users.
            </div>
          </div>
          <div
            className="aboutsegment3box"
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div>
              <i class="fa-solid aboutsegment3icon fa-address-card"></i>
            </div>
            <div className="aboutsegment3boxtitle py-3">Personalized Care</div>
            <div className="aboutsegment3boxdesc">
              We understand that each individual's needs are unique. That's why
              we take a personalized approach to care, tailoring our services to
              meet the specific needs and preferences of each client.
            </div>
          </div>
          <div
            className="aboutsegment3box"
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div>
              <i class="fa-solid fa-people-group aboutsegment3icon"></i>
            </div>
            <div className="aboutsegment3boxtitle py-3">Experienced Team</div>
            <div className="aboutsegment3boxdesc">
              With years of experience in the healthcare industry, our team
              brings a wealth of knowledge and expertise to every interaction.
              From support workers, carers to nurses, we have the skills and
              experience.
            </div>
          </div>
          <div
            className="aboutsegment3box"
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div>
              <i class="fa-solid fa-address-card aboutsegment3icon"></i>
            </div>
            <div className="aboutsegment3boxtitle py-3">
              Comprehensive Services
            </div>
            <div className="aboutsegment3boxdesc">
              We offer a range of healthcare services, including Domiciliary
              Care (Home Care Support), Palliative Care, Companionship, Live In
              Care, Nights/Sleep-In, Wake-In Nights, Respite, 1-1 Support, Care
              Support for Travel and more, Medical Staffing Whatever your needs,
              we have the services to support you.
            </div>
          </div>
        </div>
      </div>
      <section className="section5 p-md-5 p-3" id="testimonials">
        <div className="section3headingcontainer px-4">
          <div>
            <div className="lhsheading lhsheading2">
              {" "}
              <span className=""> TESTIMONIALS </span>{" "}
            </div>
            <div className=" section2exp">
              What Our <span className="section1expblue">Clients</span> Say
            </div>
          </div>
        </div>
        <div className="testimonials pt-3">
          <div className="testimonial">
            <div className="testimonialrating">
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
            </div>
            <div className="testimonialparagraph pt-3">
              "One particular staff member has been my carer for a while now.
              She’s always on time and cheerful. She’s very understanding and
              caring and tends to all of my needs. I was worried about my
              personal care with the stranger as I have catheters et cetera, but
              she handles it all very professionally. She also helps me to be
              better than I am, with exercise. Unfortunately, I’m moving and
              can’t take her with me, but anybody that gets a chance to have
              her, you lucky duck."
            </div>
            <div className="testimonialperson pt-4 d-flex">
              <div></div>
              <div className="pt-3">
                <div className="testimonialname"> Tanya M </div>
                {/* <div className="testimonialplace">USA, California</div> */}
              </div>
            </div>
          </div>

          <div className="testimonial">
            <div className="testimonialrating">
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
            </div>
            <div className="testimonialparagraph pt-3">
              “This lady has helped to care for only three to four months but
              from the outset, she stamped her mark on my home. Prior to her
              arrival, I had a succession of carers which was very unsettling.
              This lady has an established interest in it becoming a success
              story. She has the ability to manage her own workload efficiently
              as well as that of her staff, she has a cheerful demeanour which
              helps to relate to a wide range of different customers. Although
              my knowledge of her and her abilities is still very limited, there
              is nothing in her personality or work ethic that has caused me to
              question my decision to take her on.”
            </div>
            <div className="testimonialperson pt-4 ">
              <div className="pt-3">
                <div className="testimonialname"> Micheal G</div>
                {/* <div className="testimonialplace">USA, Nevada</div> */}
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="testimonialrating">
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
            </div>
            <div className="testimonialparagraph pt-3">
              “We have had home care for two weeks for my mother following her
              time in hospital. It is early days to comment but overall we have
              been pleased with the care that they have provided. Difficult to
              give a more detailed report as we have had a variety of carers up
              until now.”
            </div>
            <div className="testimonialperson pt-4 d-flex">
              <div></div>
              <div className="pt-3">
                <div className="testimonialname"> Gillan B</div>
                {/* <div className="testimonialplace">USA, California</div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="homeSegment2 p-md-5 p-4 d-grid">
        <div
          className="homeSegment2order2"
          animateIn="bounceInLeft"
          animationOut="fadeOut"
        >
          <div className=" homeSegment2container pb-4">
            <div>
              <div className="homeSegment2numbers">95%</div>
              <div className="homeSegment2subtext">Clients Satisfied</div>
            </div>
            <div>
              <div className="homeSegment2numbers">200k+</div>
              <div className="homeSegment2subtext">Care Hours Delivered</div>
            </div>
            <div>
              <div className="homeSegment2numbers">3+</div>
              <div className="homeSegment2subtext">Years of Experience</div>
            </div>
          </div>
          <div className="homeSegment2textContainer">
            <div className="homeSegment2text pt-2 pt-md-5">
              Ready to experience the warmth of compassionate care and
              personalized support?
            </div>
            <a
              href="#contact"
              className="homeSegment2contactcontainer pb-1 pt-4"
            >
              <div className="homeSegment2ccontact">Contact Us</div>
              <div>
                <img className="" src={GgreenArrow} alt="arrow" />
              </div>
            </a>
            <div className="homeSegment2textContainerline"></div>
          </div>
        </div>
        <div
          className="homeSegment2order1"
          animateIn="bounceInRight"
          animationOut="fadeOut"
        >
          <img
            className="HomeSegment2IMG"
            src={HomeSegment2IMG}
            alt="A Care Worker"
          />
        </div>
      </div>

      <section className="section7 p-md-5 p-3" id="contact">
        <div className="d-lg-flex p-md-5 contactformflex">
          <div className="slideLeft">
            <div className="contactheading">
              {" "}
              <span className="section1expblue">Connect </span> with Us Today!
            </div>
            <div className="contactsubheading py-4">
              We Are Here For You! Let us walk this journey with you because, at
              Brooks Health and Care, you are always our top priority.
            </div>
            <div className="d-flex contacticoncontainer  ">
              <div>
                <i class="fa-solid fa-envelope contacticon"></i>
              </div>
              <Link
                className="contactmail pt-1 ps-2"
                to={`mailto:admin@brookshealthandcare.com?subject=Interested%20In%20Services&body=Hello!%20I'm%20interested%20in...`}
              >
                admin@brookshealthandcare.com
              </Link>
            </div>
            <div className="pt-4">
              <div className="d-flex contacticoncontainer ">
                <div>
                  <i class="fa-solid fa-globe contacticon"></i>
                </div>
                <div className="contactmail pt-1 ps-2">
                  <a
                    className="text-black"
                    rel="stylesheet"
                    href="https://brookshealthandcare.com/"
                  >
                    brookshealthandcare.com
                  </a>
                </div>
              </div>
            </div>
            <div className="py-4">
              <div className="d-flex contacticoncontainer ">
                <div>
                  <i class="fa-solid fa-phone contacticon"></i>
                </div>
                <div className="contactmail pt-1 ps-2">
                  <a href="tel:+441234954004" className="text-black">
                    01234954004
                  </a>
                </div>
              </div>
            </div>
            <div className="">
              <div className="d-flex contacticoncontainer ">
                <div>
                  <i class="fa-solid fa-location-dot contacticon"></i>{" "}
                </div>
                <div className="contactmail pt-1 ps-2">
                  Juniper walk, Kempston, Bedford. MK42 7SX
                </div>
              </div>
            </div>
            <div className="contactmail pt-5 pb-3">
              Follow Us on Social Media
            </div>
            <div className="contacticons d-flex ">
              <div>
                <a
                  href="https://www.facebook.com/profile.php?id=61557096390057"
                  class="fa-brands fa-facebook contacticon"
                ></a>{" "}
              </div>
              <div>
                <a
                  href="https://www.instagram.com/brookshealthandcareltd?igsh=ajV4YjVhZ2Exc2tp&utm_source=qr"
                  class="fa-brands fa-instagram contacticon"
                ></a>{" "}
              </div>
              <div>
                <a
                  href="https://api.whatsapp.com/send?phone=447961929342"
                  class="fa-brands fa-whatsapp contacticon"
                ></a>{" "}
              </div>
            </div>
          </div>
          <div className="pt-4 pt-lg-1 slideRight">
          <ContactForm />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
