import React from "react";
import "../App.css";
import logo from "../images/logo.png";
import policy from "../images/Brooks_Health_and_Care_Complaints_Policy.pdf";
import footerunderline from "../images/footer_underline.png";
import footerarrow from "../images/footerarrow.png";
import homesegment8bgIMG from "../images/newhomesegment8bgIMG.jpg";
import uprightarrow from "../images/uprightarrow.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <footer className="pb-3">
     
       
        <div className="copyright  text-center ">
          <div>
            Copyright © 2024 Brooks Health and Care Limited. All rights
            reserved.
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
