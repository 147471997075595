import "../App.css";
import React, { useState } from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import menu from "../images/newmenu.svg";

import uprightarrow from "../images/uprightarrow.png";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      
    </div>
  );
}

export default Navbar;
