import React from 'react';
import ReactPixel from 'react-facebook-pixel';

function ContactForm() {
  const handleSubmit = (e) => {
    // Track the "Lead" event
    ReactPixel.track('Leads', {
      email: document.getElementById('email').value,
      phone: document.getElementById('phone').value,
    });

    // No need to prevent default submission; form will submit naturally
  };

  return (
    <form
      action="https://formspree.io/f/xjvnjbev"
      method="POST"
      className="contactform"
      onSubmit={handleSubmit}
    >
      <div className="formgroup">
        <div className="formgroupsection pb-3 pb-md-1">
          <label htmlFor="fname">First Name</label>
          <input
            type="text"
            id="fname"
            name="firstname"
            placeholder="Enter First Name"
            className="mobileinput"
          />
        </div>
        <div className="formgroupsection">
          <label htmlFor="lname">Last Name</label>
          <input
            type="text"
            id="lname"
            name="lastname"
            placeholder="Enter Last Name"
            className="mobileinput"
          />
        </div>
      </div>

      <div className="formgroupsection">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Enter Your Email"
          className="maxwidth"
        />
      </div>
      <div className="formgroupsection pt-3">
        <label htmlFor="phone">Phone</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          placeholder="Enter Your Phone Number"
          className="maxwidth"
        />
      </div>
      <div className="formgroupsection pt-3">
        <label htmlFor="message">Message</label>
        <textarea
          className="messageform"
          id="message"
          name="message"
          placeholder="Enter your Message here..."
        ></textarea>
      </div>
      <div className="contactformbtn pt-3">
        <button className="testimonialbtn footertestimonialbtn" type="submit">
          <span className="pe-2">Send Message</span>
        </button>
      </div>
    </form>
  );
}

export default ContactForm;
